import * as React from 'react';
import { useOnClickOutside } from '~/hooks';
import { useDispatch } from 'react-redux';
import { closePopup } from '~/redux/modules/popupModule';
import { POPUPS_NAME } from '~/components/PopupManager';

interface IPopupWrapper {
  children: React.ReactChild | React.ReactChild[];
  name: string;
}

const PopupWrapper = ({ children, name }: IPopupWrapper) => {
  const ref = React.useRef(null);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closePopup());
  };

  useOnClickOutside(
    ref,
    handleClose,
    [
      POPUPS_NAME.CREATE_TASK_POPUP,
      POPUPS_NAME.EDITE_TASK_POPUP,
      POPUPS_NAME.WORKER_POPUP,
      POPUPS_NAME.SET_PASSWORD_POPUP,
      POPUPS_NAME.EDIT_WORK_REQUEST_POPUP,
    ].includes(name),
  );

  return (
    <div className="popupWrapper">
      <div ref={ref}>{children}</div>
    </div>
  );
};

export default PopupWrapper;
