import { useDispatch } from 'react-redux';
import Popup from '../popup/Popup';
import { COLORS } from '~/helpers/constants';
import Button, { IButton } from '~/components/form/buttons/Button';
import { ReactComponent as CloseSvg } from '~/assets/close.svg';
import { closeDialog } from '~/redux/modules/dialogPopupModule';

export interface IDialogPopup {
  closable?: boolean;
  className?: string;
  dialogData: {
    title: string;
    message: string;
    confirm: {
      button: IButton;
      onConfirm: () => void;
    };
    cancel?: {
      button: IButton;
      onCancel?: () => void;
    };
  };
}

const DialogPopup = ({ dialogData, closable }: IDialogPopup) => {
  const { title, message, confirm, cancel } = dialogData;
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(closeDialog());
    if (cancel && cancel.onCancel !== undefined) {
      cancel.onCancel();
    }
  };
  const handleConfirm = () => {
    dispatch(closeDialog());
    confirm.onConfirm();
  };
  return (
    <div className="popup dialogPopup">
      <div className="dialogPopup_container">
        <div className="dialogPopup_header">{title}</div>
        <div className="dialogPopup_body">{message}</div>
      </div>
      <div className="dialogPopup_buttons">
        <Button
          className="dialog"
          {...cancel?.button}
          text={cancel?.button.text || 'Отмена'}
          transparent={cancel?.button.transparent || true}
          background={COLORS.BLUE}
          onClick={handleCancel}
        />
        <Button
          className="dialog"
          background={COLORS.BLUE}
          {...confirm.button}
          transparent={confirm?.button.transparent || true}
          onClick={handleConfirm}
        />
      </div>
      {closable && (
        <div className="popupClose" onClick={() => dispatch(closeDialog())}>
          <CloseSvg />
        </div>
      )}
    </div>
  );
};
export default DialogPopup;
