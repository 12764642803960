import Api, {
  IWorkRequest,
  IWorkRequestsListForm,
  WorkRequestsList,
} from 'sb_manufacturing_front_api';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  Dictionary,
  EntityId,
} from '@reduxjs/toolkit';
import store from '~/redux/configureStore';

export const workRequestsAdapter = createEntityAdapter<IWorkRequest>({
  selectId: workRequest => workRequest.id,
  sortComparer: (a, b) => b.id - a.id,
});

export interface IWorkRequestsState {
  ids: EntityId[];
  entities: Dictionary<IWorkRequest>;
}

export const getWorkRequests = createAsyncThunk(
  'getWorkRequests',
  async (date: IWorkRequestsListForm) => {
    const response: WorkRequestsList = await Api.getWorkRequestsList(date);
    const data: any = response;
    data.data.map((item: any) => {
      if (data.data.length) {
        data.data.map((workRequest: any) => {
          store.dispatch({
            type: `workRequests/add`,
            payload: {
              ...workRequest,
            },
          });
        });
      }
    });
    return response;
  },
);

const workRequestsSlice = createSlice({
  name: 'workRequests',
  initialState: workRequestsAdapter.getInitialState(),
  reducers: {
    create: workRequestsAdapter.setMany,
    update: workRequestsAdapter.upsertMany,
    updateOne: workRequestsAdapter.updateOne,
    remove: workRequestsAdapter.removeMany,
    clear: workRequestsAdapter.removeAll,
    add: workRequestsAdapter.upsertOne,
  },
});

export default workRequestsSlice.reducer;

export const { create, update, updateOne, clear, remove } = workRequestsSlice.actions;
