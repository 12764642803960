import * as React from 'react';
import classNames from 'classnames';
import { ReactComponent as CreateSvg } from '~/assets/create.svg';
import { ReactComponent as ArrowPrevSvg } from '~/assets/arrow-prev.svg';
import { ReactComponent as ArrowNextSvg } from '~/assets/arrow-next.svg';
import { ReactComponent as CopyIconSvg } from '~/assets/copy.svg';
import { ReactComponent as DownloadMiniSvg } from '~/assets/active-download-mini.svg';
import { ReactComponent as CalendarSvg } from '~/assets/calendar.svg';
import { ReactComponent as RemoveSvg } from '~/assets/remove.svg';
import { ReactComponent as FileSvg } from '~/assets/file.svg';
import { ReactComponent as BackSvg } from '~/assets/back.svg';
import { ReactComponent as RevertSvg } from '~/assets/icon/revert.svg';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';

export interface IButton {
  type?: string;
  text?: string;
  onClick?: () => void;
  icon?: boolean;
  iconType?: string;
  className?: string;
  disabled?: boolean;
  reverse?: boolean;
  background?: string;
  border?: boolean;
  transparent?: boolean;
  displayNone?: boolean;
  small?: boolean;
}

const Button = ({
  onClick,
  text,
  icon,
  type,
  iconType,
  className,
  disabled,
  reverse,
  background,
  border,
  transparent,
  displayNone,
  small,
}: IButton) => {
  const RenderIcon = (type?: string) => {
    switch (type) {
      case ICON_TYPE.CREATE:
        return <CreateSvg className="new-button__icon" />;
      case ICON_TYPE.FILE:
        return <FileSvg className="new-button__icon" />;
      case ICON_TYPE.ARROW_PREV:
        return <ArrowPrevSvg className="new-button__icon" />;
      case ICON_TYPE.ARROW_NEXT:
        return <ArrowNextSvg className="new-button__icon" />;
      case ICON_TYPE.COPY:
        return <CopyIconSvg className="new-button__icon" />;
      case ICON_TYPE.CALENDAR:
        return <CalendarSvg className="new-button__icon" />;
      case ICON_TYPE.REMOVE:
        return <RemoveSvg className="new-button__icon" />;
      case ICON_TYPE.DOWNLOAD:
        return <DownloadMiniSvg className="new-button__icon" />;
      case ICON_TYPE.BACK:
        return <BackSvg className="new-button__icon" />;
      case ICON_TYPE.REVERT:
        return <RevertSvg className="new-button__icon" />;
      default:
        return '';
    }
  };

  switch (type) {
    case BUTTON_TYPE.ICON:
      return (
        <button
          className={classNames(`new-button`, className, {
            ['reverse']: reverse,
            ['none']: displayNone,
            [BUTTON_TYPE.ICON]: BUTTON_TYPE.ICON,
          })}
          style={{ background: background }}
          onClick={onClick}
          disabled={disabled}
        >
          {icon && RenderIcon(iconType)}
          {!!text && <span className="new-button__text">{text}</span>}
        </button>
      );
    default:
      return (
        <button
          className={classNames('new-button default', className, {
            ['small']: small,
          })}
          onClick={onClick}
          disabled={disabled}
          style={
            border
              ? { border: `thin solid ${background}` }
              : transparent
              ? { background: 'transparent' }
              : { background: background }
          }
        >
          <span
            className="new-button__text"
            style={border ? { color: background } : transparent ? { color: background } : {}}
          >
            {text}
          </span>
        </button>
      );
  }
};

export default Button;
