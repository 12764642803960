import Button from '~/components/form/buttons/Button';
import { BUTTON_TYPE, ICON_TYPE } from '~/helpers/constants';
import AddNewTaskPopupScreen from './AddNewTaskPopupScreen';
import EditTaskPopupScreen from './EditTaskPopupScreen';

interface IAddNewTask {
  handleClickBack?: () => void;
  workRequestId: number;
  task?: any;
}

const AddOrEditTask = ({ handleClickBack, workRequestId, task }: IAddNewTask) => {
  return (
    <>
      <div className="users-add__header">
        <Button
          type={BUTTON_TYPE.ICON}
          text="назад"
          className="back"
          onClick={handleClickBack}
          icon
          iconType={ICON_TYPE.ARROW_PREV}
        />
      </div>
      {task ? (
        <EditTaskPopupScreen task={task} type={0} />
      ) : (
        <AddNewTaskPopupScreen template={undefined} workRequestId={workRequestId} />
      )}
    </>
  );
};

export default AddOrEditTask;
