import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { tasksStatusesAdapter } from '~/redux/modules/tasksStatusesModule';

export const workRequestCustomFieldsSelectors = tasksStatusesAdapter.getSelectors<IStore>(
  state => state.workRequestCustomFields,
);

export const workRequestCustomFields = createSelector(
  (state: IStore) => workRequestCustomFieldsSelectors.selectAll(state),
  workRequestCustomFields => workRequestCustomFields,
);
