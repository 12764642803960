import { IStore } from '~/redux/IStore';
import { createSelector } from 'reselect';

import { workRequestsAdapter } from '~/redux/modules/workRequestsModule';

export const workRequestsSelector = workRequestsAdapter.getSelectors<IStore>(
  state => state.worksRequests,
);

export const selectWorkRequests = createSelector(
  (state: IStore) => workRequestsSelector.selectAll(state),
  workRequests => workRequests,
);
