import * as React from 'react';
import SetPasswordPopup from '~/components/password/SetPasswordPopup';
import GeneratePasswordPopup from '~/components/password/GeneratePasswordPopup';
import WorkerPopup from '~/containers/workers/worker/WorkerPopup';
import NotificationsPopup from '~/containers/notifications/NotificationsPopup';
import ReportsFiltersPopup from '~/containers/reports/ReportsFiltersPopup';
import ViewTaskPopup from '~/containers/tasks/popups/ViewTaskPopup';
import HistoryTaskPopup from '~/containers/tasks/popups/HistoryTaskPopup';
import CreateTaskPopup from '~/containers/tasks/popups/CreateTaskPopup';
import EditeTaskPopup from '~/containers/tasks/popups/EditeTaskPopup';
import AdditionalShiftsPopup from '~/containers/tasks/popups/AdditionalShiftsPopup';
import AddUserPopup from '~/containers/tasks/popups/AddUserPopup';
import EditWorkRequestPopup from '~/containers/tasks/popups/work-request-popup/EditWorkRequestPopup';

interface IPopupManager {
  name: string;
  data?: any;
}

export const POPUPS_NAME = {
  SET_PASSWORD_POPUP: 'SET_PASSWORD_POPUP',
  CONFIRMATION_POPUP: 'CONFIRMATION_POPUP',
  GENERATOR_PASSWORD_POPUP: 'GENERATOR_PASSWORD_POPUP',
  WORKER_POPUP: 'WORKER_POPUP',
  NOTIFICATIONS_POPUP: 'NOTIFICATIONS_POPUP',
  ADD_USER_SHIFT_POPUP: 'ADD_USER_SHIFT_POPUP',
  EXTRA_SHIFT_POPUP: 'EXTRA_SHIFT_POPUP',
  REPORTS_FILTER_POPUP: 'REPORTS_FILTER_POPUP',
  VIEW_TASK_POPUP: 'VIEW_TASK_POPUP',
  HISTORY_TASK_POPUP: 'HISTORY_TASK_POPUP',
  CREATE_TASK_POPUP: 'CREATE_TASK_POPUP',
  EDITE_TASK_POPUP: 'EDITE_TASK_POPUP',
  EDIT_WORK_REQUEST_POPUP: 'EDIT_WORK_REQUEST_POPUP',
};

const PopupManager = ({ name, data }: IPopupManager) => {
  switch (name) {
    case POPUPS_NAME.SET_PASSWORD_POPUP:
      return <SetPasswordPopup type={data?.type} />;

    case POPUPS_NAME.GENERATOR_PASSWORD_POPUP:
      return <GeneratePasswordPopup userId={data?.userId} />;

    case POPUPS_NAME.WORKER_POPUP:
      return <WorkerPopup userId={data?.userId} />;

    case POPUPS_NAME.NOTIFICATIONS_POPUP:
      return <NotificationsPopup />;

    case POPUPS_NAME.ADD_USER_SHIFT_POPUP:
      return <AddUserPopup />;

    case POPUPS_NAME.EXTRA_SHIFT_POPUP:
      return <AdditionalShiftsPopup />;

    case POPUPS_NAME.REPORTS_FILTER_POPUP:
      return <ReportsFiltersPopup />;

    case POPUPS_NAME.VIEW_TASK_POPUP:
      return <ViewTaskPopup task={data?.task} />;

    case POPUPS_NAME.HISTORY_TASK_POPUP:
      return <HistoryTaskPopup taskLogs={data?.logs} inside={data?.inside} task={data?.task} />;

    case POPUPS_NAME.CREATE_TASK_POPUP:
      return <CreateTaskPopup template={data?.template} type={data?.type} />;

    case POPUPS_NAME.EDITE_TASK_POPUP:
      return <EditeTaskPopup task={data?.task} type={data?.type ?? 0} />;

    case POPUPS_NAME.EDIT_WORK_REQUEST_POPUP:
      return <EditWorkRequestPopup workRequest={data?.workRequest} />;

    default:
      return null;
  }
};

export default PopupManager;
