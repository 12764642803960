import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDialogPopup } from '~/components/dialog-popup/DialogPopup';

export interface IDialogPopupState {
  isVisible?: boolean;
  data?: IDialogPopup;
}

const initialState: IDialogPopupState = {};

export const popupSlice = createSlice({
  name: 'popupSlice',
  initialState,
  reducers: {
    openDialog(state, action: PayloadAction<IDialogPopupState>) {
      state.data = action.payload.data;
      state.isVisible = true;
    },
    closeDialog(state) {
      state.isVisible = false;
    },
  },
});

export default popupSlice.reducer;
export const { openDialog, closeDialog } = popupSlice.actions;
