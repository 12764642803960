import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { useAppSelector } from '~/hooks/redux';
import WorkRequest from './workRequest';
import { selectWorkRequests } from '~/redux/selectors/workRequestsSelectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getWorkRequests } from '~/redux/modules/workRequestsModule';
import { IWorkRequest } from 'sb_manufacturing_front_api';
import { selectDay } from '~/redux/selectors/stateSelectors';

export const WorkRequests = () => {
  const dispatch = useDispatch();
  SwiperCore.use([Navigation]);
  const date = useAppSelector(selectDay);
  useEffect(() => {
    dispatch(
      getWorkRequests({
        begin_date: date,
        limit: 100,
        offset: 100,
      }),
    );
  }, [date]);
  const workRequests = useAppSelector(selectWorkRequests);
  if (!workRequests.length) {
    return (
      <div className="applications__none">
        <p>У вас пока нет заявок</p>
      </div>
    );
  } else {
    return (
      <Swiper
        className="applications__applications"
        spaceBetween={8}
        slidesPerView={'auto'}
        navigation
        grabCursor
      >
        {workRequests.map(item => {
          return (
            <SwiperSlide key={item.id}>
              <WorkRequest {...item} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    );
  }
};
