import { EWorkRequestStatus } from 'sb_manufacturing_front_api';

interface IStatus {
  title: EWorkRequestStatus;
}
interface IStatusListColor {
  background: string;
  color: string;
}
const Status = ({ title }: IStatus) => {
  const statusList = ['Выполнен', 'К выполнению', 'Выполняется', 'Закрыт'];

  return <div className={`contentStatus contentPriority${title}`}>{statusList[title]}</div>;
};

export default Status;
